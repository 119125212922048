import React from 'react';

const Experience = () => (
  <div className="section">
    <h2>EXPERIENCE</h2>
    <table className="data-table">
      <tbody>
        <tr>
          <td><strong><a href="https://antiluxe.agency">A N T I L U X E, Inc</a></strong> – <span>CEO, Founder</span></td>
          <td>TECHNOLOGY | 2024</td>
        </tr>
        <tr>
          <td><strong><a href="https://redstartmedia.com">Redstart Media, Inc</a></strong> – <span>Producer, CEO, Founder</span></td>
          <td><a href="https://redstartmedia.myportfolio.com/">PORTFOLIO</a>, MEDIA | 2024</td>
        </tr>
        <tr>
          <td><strong><a href="https://perfectcircle.ai">Perfect Circle AI, Inc</a></strong> – <span>CEO, Founder</span></td>
          <td>AI INNOVATION | 2024</td>
        </tr>
        <tr>
          <td><strong><a href="https://sendmedianow.com">Send Media, LLC</a></strong> – <span>Producer, Founder</span></td>
          <td>MEDIA | 2022 – 2024</td>
        </tr>
        <tr>
          <td><strong><a href="https://beinephotography.com">Beine Photography</a></strong> – <span>Photographer, Founder</span></td>
          <td>PORTRAIT, JOURNALISM | 2019</td>
        </tr>
        <tr>
          <td><strong><a href="https://fabinsanity.com">Fab Insanity, LLC</a></strong> – <span>Principal Fabricator, Founder</span></td>
          <td>WELD, CNC, CAD/CAM, 3DP | 2020 – 2024</td>
        </tr>
        <tr>
          <td><strong>Pier Love Investments, LLC</strong> – <span>Founder</span></td>
          <td>HOLDING COMPANY | 2019</td>
        </tr>
        <tr>
          <td><strong>Pier Love Logistics, LLC</strong> – <span>Driver, Founder</span></td>
          <td>HEAVY HAUL TRUCKING | 2016 – 2019</td>
        </tr>
        <tr>
          <td><strong>Pier Love Media, Inc</strong> – <span>CEO, Founder</span></td>
          <td>MEDIA | 2015 – 2023</td>
        </tr>
        <tr>
          <td><strong>Pier Love Seafood, LLC</strong> – <span>Founder</span></td>
          <td>COMMERCIAL FISHING | 2015 – 2016</td>
        </tr>
        <tr>
          <td><strong>Top Engineer</strong> – <span>Principal Recruiter, Founder</span></td>
          <td>ENGINEERING RECRUITING | 2015 – 2023</td>
        </tr>
        <tr>
          <td><strong>Halliburton</strong> – <span>Multiple Technical Roles</span></td>
          <td>OIL FIELD | 2010 – 2015</td>
        </tr>
        <tr>
          <td><strong>Dot Net ISV, Inc.</strong> – <span>CEO, Solutions Developer</span></td>
          <td>SOFTWARE | 2005 – 2010</td>
        </tr>
        <tr>
          <td><strong>ProdevISV & Prodev Studios</strong> – <span>Founder, Developer</span></td>
          <td>SOFTWARE | 1998 – 2003</td>
        </tr>
        <tr>
          <td><strong>Planetary Computer Services</strong> – <span>President, Technician</span></td>
          <td>NETWORKS, DATA, REPAIR | 1994 – 1998</td>
        </tr>
        <tr>
          <td><strong>United States Army</strong> – <span>63E M1 Abrams</span></td>
          <td>MECH & ELEC MAINT | 1990 – 1994</td>
        </tr>
      </tbody>
    </table>
  </div>
);

export default Experience;
