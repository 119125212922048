import React from 'react';

const Education = () => (
  <div className="section">
    <h2>EDUCATION</h2>
    <table className="data-table">
      <tbody>
        <tr>
          <td><strong><a href="https://nbc.edu">Nazarene Bible College</a></strong> – <span>PASTORAL MINISTRY</span></td>
          <td>2021 – 2024</td>
        </tr>
        <tr>
          <td><strong>Nature Coast Real Estate School</strong> – <span>RE PRACTICE, LAW</span></td>
          <td>2019</td>
        </tr>
        <tr>
          <td><strong>SMTC Global – Saudi Arabia</strong> – <span>HUET, EBS, OPITO</span></td>
          <td>2024</td>
        </tr>
        <tr>
          <td><strong>Halliburton – Maintenance Training Center</strong> – <span>PLC, ELECTRONICS</span></td>
          <td>2011 - 2013</td>
        </tr>
        <tr>
          <td><strong>Halliburton - CDL Training</strong> – <span>CLASS A DRIVING + ADV DEFENSIVE</span></td>
          <td>2011</td>
        </tr>
        <tr>
          <td><strong><a href="https://www.lexpublib.org/digital-archives/collection/united-states-army-armor-school-fort-knox">United States School of Armour – Fort Knox, KY</a></strong> – <span>63E M1 ABRAMS MAINT</span></td>
          <td>1990</td>
        </tr>
        <tr>
          <td><strong><a href="https://wcc.yccd.edu/lake/">Yuba College - Lower Lake, CA</a></strong> – <span>COMPUTER SCIENCE</span></td>
          <td>1988 – 1989</td>
        </tr>
        <tr>
          <td><strong>W.C. Carle High School – Lower Lake, CA</strong> – <span>ELECTRONICS, COMPUTERS</span></td>
          <td>1986 – 1989</td>
        </tr>
      </tbody>
    </table>
  </div>
);

export default Education;
