import React from 'react';

const Competency = () => (
  <div className="section">
    <h2>COMPETENCY</h2>
    <table className="data-table">
      <tbody>
        <tr><td>Software Development & Architecture</td><td>FULL-STACK, DESIGN, ARCHITECTURE, API, DSL</td></tr>
        <tr><td>Web Technologies & E-commerce</td><td>HTML5, CSS, SHOPIFY, SEO</td></tr>
        <tr><td>Media & Design</td><td>SONY, ADOBE, DAVINCI</td></tr>
        <tr><td>CASE Tools & Code Generation</td><td>SCRIPTING, CASE, SPIDERS, BOTS</td></tr>
        <tr><td>Methodologies & Best Practices</td><td>AGILE, MVP, MVC, ORM, UI, UX, OSINT</td></tr>
        <tr><td>Server & Database Administration</td><td>DNS, FIREWALL, BARE METAL, VPS, HV</td></tr>
        <tr><td>AI Integration & Automation</td><td>PROMPT ENGINEERING, TRAINING, INTEGRATION</td></tr>
        <tr><td>CAD/CAM & Fabrication Tools</td><td>F360, MACH3, CNC, 3DP, MIG, TIG, STICK</td></tr>
        <tr><td>Networking & Systems Operations</td><td>DNS, OSI, REMOTE, SYSOPS</td></tr>
        <tr><td>Electrical & Mechanical Systems</td><td>PLC, FIBER, ARDUINO, AC, DC, DESIGN</td></tr>
        <tr><td>Material Science & Chemistry</td><td>METALLURGY, MOL SCI, ORG CHEM, PHYSICS</td></tr>
        <tr><td>Business Management</td><td>LEADERSHIP, STRATEGY, NEGOTIATION</td></tr>
        <tr><td>Process Management</td><td>SIX SIGMA, LEAN, KAISAN, CI</td></tr>
        <tr><td>Marketing & Sales Management</td><td>LEADERSHIP, STRATEGY, CRM, SEO, SMM, BRAND</td></tr>
      </tbody>
    </table>
  </div>
);

export default Competency;
