import React from 'react';
import Header from './components/Header';
import Impact from './components/Impact';
import Competency from './components/Competency';
import Experience from './components/Experience';
import Education from './components/Education';
import Footer from './components/Footer';
import './App.css';

const App = () => (
  <div className="container">
    <Header />
    <Impact />
    <Competency />
    <Experience />
    <Education />
    <Footer />
  </div>
);

export default App;
