import React from 'react';

const Header = () => (
  <>
    <h4>JAMES BEINE</h4>
    <h1>PRAGMATIC STRATEGIST</h1>
    <h2>PROBLEM SOLVER | SYSTEMS THINKER | CREATIVE TECHNOLOGIST</h2>
    <p>
      <a href="IMPACT.pdf">Turning Ideas into Solutions</a> | &nbsp;
      <a href="IMPACT.pdf">Mastering the Complex</a> | &nbsp;
      <a href="IMPACT.pdf">Leading for Tomorrow</a>
    </p>
    <p>
        <a href="Pragmatic%20Strategist.pdf">PDF</a> | &nbsp;
      <strong>
        <a href="IMPACT.pdf">Open Minded</a> | &nbsp;
      </strong>
        <a href="IMPACT.pdf">Contract</a> | &nbsp;
        <a href="IMPACT.pdf">W2</a> | &nbsp;
      <a href="IMPACT.pdf"><strong>Remote</strong></a> | &nbsp;
      <a href="IMPACT.pdf">Onsite</a> | &nbsp;
      <a href="IMPACT.pdf">Hybrid</a> | &nbsp;
      <a href="https://maps.app.goo.gl/ix9NLwdwPqx9JgvAA" target="_blank" rel="noreferrer">Tampa, FL</a>
    </p>
  </>
);

export default Header;
