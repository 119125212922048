import React from 'react';

const Impact = () => (
  <div className="section">
    <h2>IMPACT</h2>
    <p>
  I employ a pragmatic work strategy that focuses on building businesses and teams. By leading others, and pouring into their growth we achieve the best results. Along the way, science and technology play key roles. I apply engineering, recruiting, and AI in our fast-moving environments. Software development and system optimization come together to support these efforts, ensuring smooth, scalable solutions.
    </p>
    <p>
    Please consider this broad experience creates a solid base for flexibility and new ideas. What might seem chaotic at first is actually a well-organized system that turns tough problems into practical solutions.
    </p>
  </div>
);

export default Impact;
